



































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import BInput from '@/components/validation/BInput.vue'
import { MSignin } from '@/model/MSignin'
const UserModel = new MSignin()
import localStorage from '@/config/local-storage'
const ls = new localStorage(process.env.VUE_APP_LS)

@Component({
  components: {
    'AppForm': ValidationObserver,
    'AppInput': BInput
  }
})
export default class Signin extends Vue {
  //#region COMPUTED
  get loading() {
    return this.$store.getters.loading
  }
  get error() {
    return this.$store.getters.error
  }
  get token() {
    return ls.getToken()
  }
  //#endregion
  //#region DATA
  $refs!: { observer: InstanceType<typeof ValidationObserver> }
  user:MSignin = UserModel
  //#endregion
  //#region WATCH
  @Watch('token', { immediate: true })
  handler(val:string) {
    const roles = ls.getRoles()
    if (val) this.$router.push(roles[0].link)
  }
  //#endregion
  //#region METHODS
  submit () {
    this.$refs.observer.validate()
      .then(success => {
        if (!success) return
        this.$store.dispatch('UserSignin', this.user)
          .then(() => {
            const roles = ls.getRoles()
            if (!this.error) this.$router.push(roles[0].link)
          })
      })
  }
  //#endregion
  //#region MOUNTED
  mounted() {
    this.$refs.observer
  }
  //#endregion
}
